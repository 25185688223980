import { type Route } from '@angular/router';
import { CallbackComponent } from './features/callback/callback.component';
import { authGuard } from './core/guards/auth.guard';
import { ReRouteComponent } from './features/re-route/re-route.component';
import { WithNavigationComponent } from './features/with-navigation/with-navigation.component';
import { requirePermissions } from './core/guards/permissions.guard';
import { ErrorPageComponent } from './features/error-page/error-page.component';
import { tenantGuard } from './core/guards/tenant.guard';
import { onboardGuard } from './core/guards/onboard.guard';

export const appRoutes: Route[] = [
  {
    path: '',
    component: ReRouteComponent,
  },
  {
    path: '',
    component: WithNavigationComponent,
    canActivate: [authGuard, requirePermissions(), tenantGuard, onboardGuard],
    loadChildren: () => [
      {
        path: 'dashboard',
        canActivate: [requirePermissions('DASHBOARD_READ')],
        loadChildren: () => import('./features/dashboard/dashboard.routes').then((m) => m.routes),
      },
      {
        path: 'slides',
        canActivate: [requirePermissions('SLIDE_SCANS_READ')],
        loadChildren: () => import('./features/slides/slides.routes').then((m) => m.routes),
      },
      {
        path: 'setup',
        canActivate: [requirePermissions('SETUP_WRITE')],
        loadChildren: () => import('./features/setup/setup.routes').then((m) => m.routes),
      },
      {
        path: 'user-management',
        canActivate: [requirePermissions('USER_MANAGEMENT_WRITE')],
        loadChildren: () => import('./features/user-management/user-management.routes').then((m) => m.routes),
      },
    ],
  },
  {
    path: 'onboard/dataplane',
    canActivate: [authGuard, requirePermissions('DATAPLANE_CONFIGURATION_WRITE')],
    loadChildren: () => import('./features/onboard-dataplane/onboard-dataplane.routes').then((m) => m.routes),
  },
  {
    path: 'tenant/selection',
    canActivate: [authGuard],
    loadChildren: () => import('./features/tenant-selection/tenant-selection.routes').then((m) => m.routes),
  },
  {
    path: 'callback',
    loadComponent: () => CallbackComponent,
  },
  {
    path: 'error/:error-code',
    component: ErrorPageComponent,
  },
  {
    path: '**',
    redirectTo: '/error/404',
  },
];
