import { Component, OnInit, inject, input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'dpqa-re-route',
  standalone: true,
  template: '',
})
export class ReRouteComponent implements OnInit {
  private router = inject(Router);

  public route = input<string>();

  ngOnInit(): void {
    this.router.navigate([this.route() ? this.route() : '/dashboard'], {
      queryParamsHandling: 'merge',
      queryParams: { route: undefined },
    });
  }
}
