import { Component, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { injectRumService } from './shared/services/rum/rum.service';

@Component({
  standalone: true,
  imports: [RouterModule],
  selector: 'dpqa-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.less',
  encapsulation: ViewEncapsulation.Emulated,
})
export class AppComponent implements OnInit {
  private router = inject(Router);
  private translateService = inject(TranslateService);
  private rumService = injectRumService();

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = new URL(event.url, location.origin);
        const uuid = new RegExp(/[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
        this.rumService.awsRum()?.recordPageView(url.pathname.replace(uuid, ':id'));
      }
    });

    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
  }
}
