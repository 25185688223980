import { CommonModule } from '@angular/common';
import { Component, OnInit, inject, input } from '@angular/core';
import { AuthService } from '../../shared/services/auth/auth.service';
import { StorageService } from '../../shared/services/storage/storage.service';

@Component({
  selector: 'dpqa-callback',
  standalone: true,
  imports: [CommonModule],
  template: '',
})
export class CallbackComponent implements OnInit {
  private authService = inject(AuthService);
  private storageService = inject(StorageService);

  public code = input.required<string>();
  public state = input.required<string>();

  ngOnInit(): void {
    const state = this.storageService.get<string>('state');

    if (state && this.state() === state) {
      this.authService.exchangeAccessToken(this.code()).subscribe();
    }
  }
}
