import { HttpErrorResponse, type HttpInterceptorFn, type HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, of, switchMap, throwError } from 'rxjs';
import { type AbstractAuthService } from '../../shared/services/auth/abstract-auth.service';
import { AuthService } from '../../shared/services/auth/auth.service';
import { StorageService } from '../../shared/services/storage/storage.service';
import type { AbstractStorageType } from '../../shared/services/storage/abstract-storage.service';
import { TenantService } from '../../shared/services/tenant/tenant.service';
import { z } from 'zod';

const addAuthHeaders = (
  authService: AbstractAuthService,
  storageService: AbstractStorageType,
  req: HttpRequest<unknown>,
) =>
  req.clone({
    ...(req.url.includes('result.json') // Not setting auth headers when we request the ai results json
      ? {}
      : {
          setHeaders: {
            'Authorization': `Bearer ${authService.getAccessToken()}`,
            'dpqa-tenant': storageService.get('current_tenant') || 'PICK_FIRST',
          },
        }),
  });

export const authorizationInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const storageService = inject(StorageService);
  const tenantService = inject(TenantService);

  const [currentTenant, storedTenant] = [tenantService.currentTenant, storageService.get('current_tenant')];

  if (!currentTenant && storedTenant && !z.string().uuid().safeParse(storedTenant).success) {
    storageService.remove('current_tenant');
  }

  if (currentTenant && storedTenant && currentTenant !== storedTenant) {
    storageService.set('current_tenant', currentTenant);
  }

  return next(addAuthHeaders(authService, storageService, req)).pipe(
    catchError((err) => {
      if (err instanceof HttpErrorResponse && err.status === 401) {
        // asserting its not undefined because page will be exited
        return authService.refreshToken().pipe(
          switchMap(() => next(addAuthHeaders(authService, storageService, req))),
          catchError((errAfterRefresh) => {
            if (errAfterRefresh instanceof HttpErrorResponse && [401].includes(errAfterRefresh.status)) {
              authService.logout();
            }

            return of(errAfterRefresh);
          }),
        );
      }
      return throwError(() => err);
    }),
  );
};
