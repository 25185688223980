import { type ErrorHandler, inject } from '@angular/core';
import { RUMService } from '../services/rum/rum.service';

export class RumErrorHandler implements ErrorHandler {
  private rumService = inject(RUMService);

  handleError(error: any): void {
    this.rumService.awsRum()?.recordError(error);
  }
}
