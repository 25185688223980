<div class="error-page">
  <div class="error-page-container">
    <dpqa-icon-alert
      [iconName]="errorIcon()"
      [iconSize]="7.7"
      [alertMessage]="'COMMON.ERRORS.' + errorCode() + '.TITLE' | translate"
      [alertDescription]="'COMMON.ERRORS.' + errorCode() + '.DESCRIPTION' | translate"
      [type]="'error'"
    />
    @if (errorCode() === '403' && amountOfTenants() >= 1) {
    <dpqa-button routerLink="/tenant-selection" type="primary">
      {{ "COMMON.SWITCH_TENANT" | translate }}
    </dpqa-button>
    }

    @if (errorCode() === '404') {
    <dpqa-button routerLink="/slides" replaceUrl type="primary">
      {{ "COMMON.GO_TO_SLIDES" | translate }}
    </dpqa-button>
    }
  </div>
</div>
